import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

export const GoogleMapIcon:FunctionComponent<{className: string}> = ({className}) => {
   
    return (
        <a className={ className } href="https://g.page/Craft-Beer-Bar-Springfield?share" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={ faLocationDot } />
            {/* <svg id="googlemapicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <g>
                    <path d="M256,0C159.969,0,82.125,77.859,82.125,173.906C82.125,269.938,236.797,512,256,512
                        c19.219,0,173.875-242.063,173.875-338.094C429.875,77.859,352.031,0,256,0z M256,240.406c-36.719,0-66.5-29.781-66.5-66.5
                        c0-36.75,29.781-66.531,66.5-66.531s66.516,29.781,66.516,66.531C322.516,210.625,292.719,240.406,256,240.406z"/>
                </g>
            </svg>		 */}
            <span className="sr-only">Google Map</span>
		</a>
    )
}