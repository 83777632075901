import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { UntappdItem } from "../../models/UntappdItem";
import { BeerRating } from "./BeerRating";
import { BeerDescription } from "./BeerDescription";
import { BeerContainers } from "./BeerContainers";
import { BeerDetails } from "./BeerDetails";
import { BeerImage } from "./BeerImage";
import { BeerTapName } from "./BeerTapName";
import { BeerStyle } from "./BeerStyle";
import { BreweryName } from "./BreweryName";

type Props = {
  tap_data: UntappdItem;
};

export const BeerTapCard = (props: Props) => {
  return (
    <>
      <Card
        key={props.tap_data.tap_number}
        raised={true}
        className="w-full my-3"
      >
        <CardContent>
          <div className="grid grid-cols-6 gap-5">
            <div className="grid grid-cols-1 content-start">
              <BeerImage
                label_image_thumb={props.tap_data.label_image_thumb}
                name={props.tap_data.name}
                created_at={props.tap_data.created_at}
              />
            </div>
            <div className="w-full col-span-5">
              <div>
                <BeerTapName
                  untappd_beer_slug={props.tap_data.untappd_beer_slug}
                  untappd_id={props.tap_data.untappd_id}
                  tap_number={props.tap_data.tap_number}
                  tap_name={props.tap_data.name}
                />
                <BeerStyle style={props.tap_data.style} />
              </div>
              <div>
                <BreweryName
                  untappd_brewery_id={props.tap_data.untappd_brewery_id}
                  brewery={props.tap_data.brewery}
                />
              </div>
              <div className="flex gap-1">
                <BeerDetails
                  abv={props.tap_data.abv}
                  ibu={props.tap_data.ibu}
                  nitro={props.tap_data.nitro}
                />
              </div>
              <div>
                <BeerContainers containers={props.tap_data.containers} />
              </div>
              <div className="flex gap-2">
                <BeerDescription description={props.tap_data.description} />
              </div>
              <div className="flex gap-2 pt-3">
                <BeerRating rating={props.tap_data.rating} />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
