import { FunctionComponent } from 'react';

export const FacebookIcon:FunctionComponent<{className: string}> = ({className}) => {
    return (
        <a className={ className } href="https://www.facebook.com/craftbeerspringfield/" target="_blank" rel="noreferrer">
            <svg id="facebookicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path d="M29,16.07912A13,13,0,1,0,13.96875,28.94694V19.84447H10.668V16.07912h3.30078v-2.8698c0-3.26466,1.94081-5.06795,4.91029-5.06795a19.95289,19.95289,0,0,1,2.91.25441v3.20563H20.14979a1.88079,1.88079,0,0,0-2.11854,2.03423v2.44348h3.60547l-.57637,3.76535h-3.0291v9.10247A13.02132,13.02132,0,0,0,29,16.07912"/>
            </svg>
			<span className="sr-only">Facebok</span>
		</a>
    );
};