import Typography from "@mui/material/Typography";

type Props = {
  style: string;
};

export const BeerStyle = (props: Props) => {
  return (
    <>
      <Typography variant="subtitle1" component="div">
        {props.style}
      </Typography>
    </>
  );
};
