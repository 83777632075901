import Typography from "@mui/material/Typography";

type Props = {
  untappd_brewery_id: number;
  brewery: string;
};

export const BreweryName = (props: Props) => {
  return (
    <>
      <a
        href={`https://untappd.com/brewery/${props.untappd_brewery_id}`}
        target="_blank"
        rel="noreferrer"
      >
        <Typography
          sx={{ textDecoration: "underline" }}
          className="hover:bg-gray-200"
          variant="subtitle1"
          component="div"
        >
          {props.brewery}
        </Typography>
      </a>
    </>
  );
};
