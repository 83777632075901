import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { DrizzlyIcon } from "./DrizzlyIcon";
import { ShopIcon } from "./ShopIcon";
import { FacebookIcon } from "./FacebookIcon";
import { GoogleMapIcon } from "./GoogleMapIcon";
import { InstagramIcon } from "./InstagramIcon";
import { TwitterIcon } from "./TwitterIcon";

export const ContactInfo = () => {
  return (
    <div className="px-6 md:pt-30">
      <div className="text-white text-xl bold ml-8 md:ml-0">
        Contact Information
      </div>
      <div className="text-white text-md flex hover:text-red-500 mt-3 mb-1">
        <a href="tel:+16109903013" target="_self">
          <FontAwesomeIcon className="mr-3" icon={faPhone} />
        </a>
        <a href="tel:+16109903013" target="_self">
          <p>(610) 990-3013</p>
        </a>
      </div>
      <div className="text-white text-md flex hover:text-red-500 mb-1">
        <div className="translate-y-1/4">
          <a
            href="https://g.page/Craft-Beer-Bar-Springfield?share"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon className="mr-3" icon={faLocationDot} />
          </a>
        </div>
        <a
          href="https://g.page/Craft-Beer-Bar-Springfield?share"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            35 Baltimore Pike
            <br></br>
            Springfield, PA 19064
          </div>
        </a>
      </div>
      <span className="flex place-content-start mt-5">
        <ShopIcon className="fill-white text-white text-2xl h-9 w-9 ml-2 hover:text-red-500" />
        <FacebookIcon className="fill-white text-white h-8 w-8 hover:fill-red-500" />
        <InstagramIcon className="fill-white text-white h-8 w-8 hover:fill-red-500" />
        <TwitterIcon className="fill-white text-white h-8 w-8 hover:fill-red-500" />
        <GoogleMapIcon className="fill-white text-white text-2xl h-9 w-9 ml-2 hover:text-red-500" />
      </span>
    </div>
  );
};
