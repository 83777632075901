import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import sweatshirt1 from "../public/images/light-wood-background.jpg";

export const Merchandise = () => {
  return (
    <div className="m-0 p-0">
      <div className="w-full h-32 md:h-64 bg-fixed bg-cover bg-center flex justify-center items-center bg-store">
        <div className="w-full p-0 m-0">
          <div className="bg-black/70 sm:mx-10 sm:mt-5 py-2 mb-2">
            <h1 className="text-2xl font-roboto-slab text-center text-white">
              Merchandise
            </h1>
          </div>
        </div>
      </div>
      <div className="pb-10 md:pb-20 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-10 md:gap-20 justify-center items-center bg-store">
        <Card className="mx-5 md:w-1/2">
          <CardHeader shadow={false} floated={false}>
            <img src={sweatshirt1} alt="sweatshirt" />
          </CardHeader>
          <CardBody className="pb-0 mb-0">
            <Typography variant="h4" color="black">
              Sweatshirt
            </Typography>
          </CardBody>
          <CardFooter>
            <Typography variant="paragraph">Sizes: XL, L, M, SM, XS</Typography>
            <Typography variant="paragraph">
              Colors: Black, Green, Red
            </Typography>
            <Typography variant="paragraph">Nice Hoodie!</Typography>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};
