import React, { useEffect, useState, FunctionComponent } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import { Typography } from "@material-tailwind/react";
import { CalendarDayProps, CalendarDay } from "./CalendarDay";
import { Button } from "@material-tailwind/react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { UntappdEventResponse } from "../models/UntappdEventResponse";
//import { UntappdMenuAnnouncementResponse } from "../models/UntappdMenuAnnouncementResponse";
import { EventInstance } from "../models/EventInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const Calendar = () => {
  const initialEventValue = {
    events: [
      {
        city: "",
        country: "",
        created_at: "",
        description: "",
        end_time: "",
        end_time_in_zone: "",
        from_facebook: false,
        id: 0,
        latitude: 0,
        link: "",
        location_id: 0,
        location_name: "",
        longitude: "",
        name: "",
        place_json: {
          id: "",
          url: "",
          icon: "",
          name: "",
          scope: "",
          types: [],
          geometry: {
            location: {
              lat: 0,
              lng: 0,
            },
          },
        },
        start_time: "",
        start_time_in_zone: "",
        state: "",
        street: "",
        updated_at: "",
        zip: "",
      },
    ],
  };

  //   const initialMenuAnnValue = {
  //     menu_announcements: {
  //       current: [
  //         {
  //           cancelled: false,
  //           content: "",
  //           created_at: "",
  //           display_on_digital_board: false,
  //           display_on_embed_menu: false,
  //           display_on_print_menu: false,
  //           id: 0,
  //           last_published_at: "",
  //           location_id: 0,
  //           next_publish_at: "",
  //           period: "",
  //           post_to_facebook: false,
  //           post_to_twitter: false,
  //           type: "",
  //           updated_at: "",
  //         },
  //       ],
  //       upcoming: [
  //         {
  //           cancelled: false,
  //           content: "",
  //           created_at: "",
  //           display_on_digital_board: false,
  //           display_on_embed_menu: false,
  //           display_on_print_menu: false,
  //           id: 0,
  //           last_published_at: "",
  //           location_id: 0,
  //           next_publish_at: "",
  //           period: "",
  //           post_to_facebook: false,
  //           post_to_twitter: false,
  //           type: "",
  //           updated_at: "",
  //         },
  //       ],
  //       weekly: [
  //         {
  //           cancelled: false,
  //           content: "",
  //           created_at: "",
  //           display_on_digital_board: false,
  //           display_on_embed_menu: false,
  //           display_on_print_menu: false,
  //           id: 0,
  //           last_published_at: "",
  //           location_id: 0,
  //           next_publish_at: "",
  //           period: "",
  //           post_to_facebook: false,
  //           post_to_twitter: false,
  //           type: "",
  //           updated_at: "",
  //         },
  //       ],
  //     },
  //   };
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isBetween);
  dayjs.tz.setDefault("America/New_York");

  const [calendarDate, setCalendarDate] = useState(dayjs());
  const [calendarData, setCalendarData] = useState([] as CalendarDayProps[]);
  const [eventData, setEventData] =
    useState<UntappdEventResponse>(initialEventValue);
  //   const [menuData, setMenuData] =
  //     useState<UntappdMenuAnnouncementResponse>(initialMenuAnnValue);
  const [isEventLoading, setIsEventLoading] = useState(true);
  //   const [isMenuLoading, setIsMenuLoading] = useState(true);
  const [isNarrowScreen, setIsNarrowScreen] = useState(false);

  const headerStyle = "text-center bg-blue-gray-300 rounded-full mb-1 mx-2";

  //This useEffect will be used to detect screen size
  useEffect(() => {
    // set initial value
    const mediaWatcher = window.matchMedia("(max-width: 850px)");
    setIsNarrowScreen(mediaWatcher.matches);

    //watch for updates
    function updateIsNarrowScreen(e: any) {
      setIsNarrowScreen(e.matches);
    }
    mediaWatcher.addEventListener("change", updateIsNarrowScreen);

    // clean up after ourselves
    return function cleanup() {
      mediaWatcher.removeEventListener("change", updateIsNarrowScreen);
    };
  });

  useEffect(() => {
    const startDate = calendarDate.set("date", 1);
    const endDate = calendarDate.set("date", calendarDate.daysInMonth());

    //Row Count
    const daysOnRowOne = 7 - startDate.day();
    const remainingDays = calendarDate.daysInMonth() - daysOnRowOne;
    const rowCount = 1 + Math.ceil(remainingDays / 7);

    const baseUrl = process.env.REACT_APP_UNTAPPD_API_BASE_URL;
    const user = process.env.REACT_APP_UNTAPPD_API_USER;
    const apikey = process.env.REACT_APP_UNTAPPD_API_KEY;
    const locationId = process.env.REACT_APP_UNTAPPD_LOCATION_ID;

    const fetchData = async () => {
      await axios
        .get(`${baseUrl}locations/${locationId}/events`, {
          auth: {
            username: user!,
            password: apikey!,
          },
        })
        .then((response) => {
          setEventData(response.data);
          setIsEventLoading(false);
        })
        .catch((error) => {
          console.log("Error", error);
        });

      //   await axios
      //     .get(`${baseUrl}locations/${locationId}/menu_announcements`, {
      //       auth: {
      //         username: user!,
      //         password: apikey!,
      //       },
      //     })
      //     .then((response) => {
      //       setMenuData(response.data);
      //       console.log(response.data);
      //       setIsMenuLoading(false);
      //     })
      //     .catch((error) => {
      //       console.log("Error", error);
      //     });
    };

    fetchData();
    let calendarArray = new Array<CalendarDayProps>();
    let dayIndex = 1;
    for (let i: number = 0; i <= rowCount * 7 - 1; i++) {
      if (i < startDate.day()) {
        calendarArray.push({ key: i, day: 0, title: "" });
      } else if (dayIndex <= calendarDate.daysInMonth()) {
        calendarArray.push({ key: i, day: dayIndex, title: "" });
        dayIndex++;
      } else {
        calendarArray.push({ key: i, day: 0, title: "" });
      }
    }
    setCalendarData(calendarArray);
  }, [calendarDate]);

  const previousMonth = () => {
    var newDate = calendarDate.add(-1, "month");
    setCalendarDate(newDate);
  };

  const nextMonth = () => {
    var newDate = calendarDate.add(1, "month");
    setCalendarDate(newDate);
  };

  const currentEvents = () => {
    let startDate = calendarDate.set("date", 1);
    let endDate = calendarDate.set("date", calendarDate.daysInMonth());

    let result = new Array<EventInstance>();
    if (eventData !== null && eventData.events?.length > 0) {
      var currentEvents = eventData.events.filter((sd) =>
        dayjs(sd.start_time).isBetween(startDate, endDate, "day", "[]")
      );
      currentEvents.forEach((item, index, arr) => {
        const eventInstance = {
          id: arr[index].id,
          startDate: arr[index].start_time,
          endDate: arr[index].end_time,
          title: arr[index].name,
          description: arr[index].description,
          link: arr[index].link,
        };
        result.push(eventInstance);
      });
    }
    // if (menuData !== null && menuData.menu_announcements?.weekly?.length > 0) {
    //   menuData.menu_announcements?.weekly.forEach((item, index, arr) => {
    //     const eventInstance = {
    //       id: arr[index].id,
    //       startDate: arr[index].next_publish_at,
    //       title: "Happy Hour",
    //       description: arr[index].content,
    //     };
    //     result.push(eventInstance);
    //   });
    // }
    return result;
  };

  if (isEventLoading === true) {
    return (
      <div className="flex justify-center p-20">
        <CircularProgress />
      </div>
    );
  }

  if (isNarrowScreen) {
    return (
      <div className="py-3">
        <div className="grid grid-cols-3 pb-5 mx-2">
          <Button
            variant="gradient"
            onClick={previousMonth}
            className="w-14 text-center place-self-start"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div className="text-center place-self-center">
            <Typography variant="h5">
              {calendarDate.format("MMM YYYY")}
            </Typography>
          </div>
          <Button
            variant="gradient"
            onClick={nextMonth}
            className="w-14 text-center place-self-end"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
        <div className="w-full">
          {calendarData.map((props) => {
            var result = currentEvents()?.filter((obj) => {
              return dayjs(obj.startDate).date() === props.day;
            });
            if (props.day == 0 || !result || result.length <= 0) {
              return;
            } else {
              return (
                <CalendarDay
                  key={props.key}
                  day={props.day}
                  title={result[0].title}
                  startTime={result[0].startDate}
                  endTime={result[0].endDate}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="lg:px-20 lg:py-10 md:px-10 md:py-5">
      <div className="grid grid-cols-3 pb-5 md:pb-10">
        <Button
          variant="gradient"
          onClick={previousMonth}
          className="w-28 text-center place-self-start"
        >
          Previous
        </Button>
        <div className="text-center place-self-center">
          <Typography variant="h2">
            {calendarDate.format("MMMM YYYY")}
          </Typography>
        </div>
        <Button
          variant="gradient"
          onClick={nextMonth}
          className="w-28 text-center place-self-end"
        >
          Next
        </Button>
      </div>
      <div className="w-full">
        <div className="grid grid-cols-7">
          <div className={headerStyle}>Sunday</div>
          <div className={headerStyle}>Monday</div>
          <div className={headerStyle}>Tuesday</div>
          <div className={headerStyle}>Wednesday</div>
          <div className={headerStyle}>Thursday</div>
          <div className={headerStyle}>Friday</div>
          <div className={headerStyle}>Saturday</div>
          {calendarData.map((props) => {
            var result = currentEvents()?.filter((obj) => {
              return dayjs(obj.startDate).date() === props.day;
            });
            if (props.day === 0 || !result || result.length <= 0) {
              return <CalendarDay key={props.key} day={props.day} title="" />;
            } else {
              return (
                <CalendarDay
                  key={props.key}
                  day={props.day}
                  title={result[0].title}
                  startTime={result[0].startDate}
                  endTime={result[0].endDate}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
