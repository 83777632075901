import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Collapse,
  IconButton,
  Chip,
  ChipProps,
} from "@material-tailwind/react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import logo from "../public/images/Craft-Beer-Store-logo-1.jpg";
// import { CurrentDateTimeProperties } from "../models/DailyOperation";
// import { getCurrentDateTimeProperties } from "../api/DateTimeStore";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { UntappdHoursResponse } from "../models/UntappdHoursResponse";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { NavFloatingLink } from "./NavFloatingLink";

export const NavFloating = () => {
  const initialHours = {
    hours: [
      {
        always_open: false,
        close_at: "",
        closed: false,
        created_at: "",
        day: "",
        id: 0,
        location_id: 0,
        open_at: "",
        updated_at: "",
      },
    ],
  };

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/New_York");

  const [openNav, setOpenNav] = React.useState(false);
  const [statusColor, setStatusColor] = useState("red" as ChipProps["color"]);
  const [statusText, setStatusText] = useState("CLOSED");
  const [hoursData, setHoursData] =
    useState<UntappdHoursResponse>(initialHours);
  const [isHoursLoading, setIsHoursLoading] = useState(true);
  const refreshTimeMs = 60000;

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
    fetchData();
    const comInterval = setInterval(fetchData, refreshTimeMs);
    return () => clearInterval(comInterval);
  }, []);

  const fetchData = async () => {
    const baseUrl = process.env.REACT_APP_UNTAPPD_API_BASE_URL;
    const user = process.env.REACT_APP_UNTAPPD_API_USER;
    const apikey = process.env.REACT_APP_UNTAPPD_API_KEY;
    const locationId = process.env.REACT_APP_UNTAPPD_LOCATION_ID;

    await axios
      .get(`${baseUrl}locations/${locationId}/hours`, {
        auth: {
          username: user!,
          password: apikey!,
        },
      })
      .then((response) => {
        setHoursData(response.data);
        isOpen(response.data);
        setIsHoursLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const isOpen = (data: UntappdHoursResponse) => {
    const now = dayjs().utc().tz("America/New_York");
    let currentlyOpen = false;
    for (let i = 0; i < data.hours.length; i++) {
      //untappd, 0=monday, java, 0=sunday
      let dayIndex = 0;
      if (i < data.hours.length - 1) {
        dayIndex = i + 1;
      }

      if (now.day() !== dayIndex) {
        continue;
      }

      const openAt = dayjs.tz(data.hours[i].open_at);
      const closeAt = dayjs.tz(data.hours[i].close_at);

      const nowSeconds = now.hour() * 3600 + now.minute() * 60 + now.second();
      const openSeconds =
        openAt.hour() * 3600 + openAt.minute() * 60 + openAt.second();
      const closeSeconds =
        closeAt.hour() * 3600 + closeAt.minute() * 60 + closeAt.second();

      if (nowSeconds >= openSeconds && nowSeconds < closeSeconds) {
        currentlyOpen = true;
      }
      //Found the day, so no need to check any further
      break;
    }
    // //todo: calculate happy hour
    // //      setStatusColor("yellow");
    // //      setStatusText("HAPY HOUR");

    if (currentlyOpen) {
      setStatusColor("green");
      setStatusText("OPEN");
    } else {
      setStatusColor("red");
      setStatusText("CLOSED");
    }
  };

  const linkOnClickHandler = () => {
    setOpenNav(false);
  };

  const navList = (
    <ul className="flex flex-col md:mb-0 md:mt-0 md:flex-row md:items-center md:gap-6">
      <NavFloatingLink text="Home" link="/" onClick={linkOnClickHandler} />
      <NavFloatingLink
        text="On Tap"
        link="/ontap"
        onClick={linkOnClickHandler}
      />
      <NavFloatingLink
        text="Food Menu"
        link="/foodmenu"
        onClick={linkOnClickHandler}
      />
      <NavFloatingLink
        text="Events"
        link="/events"
        onClick={linkOnClickHandler}
      />
      {/* <NavFloatingLink text="Calendar" link="/calendar" /> */}
      <NavFloatingLink
        text="Shop Online"
        link="https://order.toasttab.com/online/craft-beer-store-35-baltimore-pike"
        onClick={linkOnClickHandler}
        target="_blank"
        rel="external noopener noreferrer"
      />
    </ul>
  );

  return (
    <>
      <Navbar className="print:hidden sticky inset-0 z-10 h-max max-w-full rounded-none px-4 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="flex items-center justify-start">
            <Link to="/">
              <img
                className="h-10 sm:h-16 w-auto"
                src={logo}
                alt="Craft Beer Store"
              />
            </Link>
            <Chip
              size="lg"
              variant="ghost"
              value={statusText}
              color={statusColor}
              icon={<CalendarDaysIcon />}
              className="ml-3"
            />
          </div>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav}>{navList}</Collapse>
      </Navbar>
      <div className="mx-auto max-w-screen">
        <Outlet />
      </div>
      <div className="print:hidden">
        <Footer />
      </div>
    </>
  );
};
