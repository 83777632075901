import { UntappdContainer } from "../../models/UntappdContainer";
import Typography from "@mui/material/Typography";

type Props = {
  containers: UntappdContainer[];
};

export const BeerContainers = (props: Props) => {
  return (
    <>
      {props.containers.length > 0 &&
        props.containers.map((cont) => {
          if (cont.id > 0) {
            return (
              <div key={cont.id}>
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{ fontWeight: "bold" }}
                >
                  {cont.currency_symbol}
                  {cont.price}
                </Typography>
                <Typography variant="subtitle1" component="span">
                  &nbsp;• ({cont.name})
                </Typography>
                {/* {cont.calories > 0 && (
                                  <Typography
                                    variant="subtitle1"
                                    component="span"
                                  >
                                    &nbsp;• Cal: {cont.calories}
                                  </Typography>
                                )} */}
              </div>
            );
          }
        })}
    </>
  );
};
