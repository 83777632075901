import React, { useState, useEffect } from "react";
import { UntappdItemResponse } from "../models/UntappdItemResponse";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

export const FoodMenu = () => {
  const menuInitialValue = {
    items: [
      {
        abv: 0,
        brewery: "",
        brewery_country: "",
        brewery_location: "",
        calories: 0,
        cask: false,
        containers: [
          {
            calories: 0,
            container_size_id: 0,
            container_size: {
              id: 0,
              name: "",
              ounces: 0,
              position: 0,
              created_at: "",
              updated_at: "",
            },
            created_at: "",
            currency: "",
            currency_symbol: "",
            id: 0,
            item_id: 0,
            name: "",
            position: 0,
            price: 0,
            updated_at: "",
          },
        ],
        created_at: "",
        default_image: "",
        description: "",
        ibu: 0,
        id: 0,
        label_image: "",
        label_image_hd: "",
        label_image_thumb: "",
        in_production: false,
        local: false,
        nitro: false,
        name: "",
        rating: 0,
        rating_count: 0,
        style: "",
        tap_number: "",
        untappd_beer_slug: "",
        untappd_brewery_id: 0,
        untappd_id: 0,
        updated_at: "",
      },
    ],
  };
  const sectionInitialValue = {
    section: {
      created_at: "",
      id: 0,
      items_count: 0,
      menu_id: 0,
      name: "",
      position: 0,
      public: false,
      type: "",
      updated_at: "",
    },
  };
  const [isMenuLoading, setIsMenuLoading] = useState(true);
  const [menuData, setMenuData] =
    useState<UntappdItemResponse>(menuInitialValue);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_UNTAPPD_API_BASE_URL;
    const user = process.env.REACT_APP_UNTAPPD_API_USER;
    const apikey = process.env.REACT_APP_UNTAPPD_API_KEY;
    const sectionId = process.env.REACT_APP_UNTAPPD_MENU_SECTION_ID;
    const fetchData = async () => {
      await axios
        .get<UntappdItemResponse>(
          `${baseUrl}sections/${sectionId}/items?full=true`,
          {
            auth: {
              username: user!,
              password: apikey!,
            },
          }
        )
        .then((response) => {
          setMenuData(response.data);
          setIsMenuLoading(false);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };
    fetchData();
  }, []);

  if (isMenuLoading === true) {
    return (
      <div className="flex justify-center p-20">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="py-10 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-10 md:gap-20 justify-center items-center bg-store">
      <div className="w-full h-8 md:h-16 bg-fixed bg-cover bg-center flex justify-center items-center bg-store">
        <div className="w-full p-0 m-0">
          <div className="bg-black/80 sm:mx-10 sm:mt-5 py-2 mb-2">
            <h1 className="text-2xl font-roboto-slab text-center text-white">
              Food Menu
            </h1>
          </div>
        </div>
      </div>
      <div className="px-5 md:pb-20 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-5 md:gap-20 justify-center items-center bg-store">
        <Card className="w-full md:w-1/2 bg-menu">
          <CardBody className="text-center">
            {menuData?.items.map((data, key) => {
              if (data.name && data.name !== "") {
                return (
                  <div className="w-full pb-3" key={key}>
                    <Typography variant="h6" component="div">
                      {data.name}
                    </Typography>
                    <Typography variant="paragraph" component="div">
                      {data.description}
                    </Typography>
                    {data.containers.length > 0 &&
                      data.containers.map((cont, ckey) => {
                        if (cont.id > 0) {
                          return (
                            <div key={cont.id}>
                              <Typography>
                                {cont.name !== "" && cont.name.trim() + ": "}
                                {cont.currency_symbol}
                                {cont.price}
                              </Typography>
                              {cont.calories > 0 && (
                                <Typography>
                                  Calories: {cont.calories}
                                </Typography>
                              )}
                            </div>
                          );
                        }
                      })}
                  </div>
                );
              }
            })}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
