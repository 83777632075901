import { BusinessHours } from "./BusinessHours";
import { ContactInfo } from "./ContactInfo";

export const Footer = () => {
  return (
    <div className="bg-cbsGreen-dark">
      <div className="grid grid-cols-12 p-3">
        <div className="w-full col-span-12 content-center 2xl:col-span-4 xl:col-span-4 lg:col-span-5 md:col-span-6">
          <BusinessHours />
        </div>
        <div className="mt-5 md:mt-0 w-full align-bottom content-justify col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-5 md:col-span-6">
          <ContactInfo />
        </div>
      </div>
      <div>
        <p className="text-white text-xs text-center">
          © 2023 Craft Beer Store - Springfield PA. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};
