import Typography from "@mui/material/Typography";

type Props = {
  description: string;
};

export const BeerDescription = (props: Props) => {
  return (
    <>
      <Typography variant="subtitle2" component="div">
        {props.description}
      </Typography>{" "}
    </>
  );
};
