import { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import { UntappdEventResponse } from "../models/UntappdEventResponse";
import { EventInstance } from "../models/EventInstance";
import { HidableDiv } from "./components/hidable-div";

export const Home = () => {
  const maxEventDuration = 7; //days
  const [currentEvents, setCurrentEvents] = useState<EventInstance[]>(
    new Array<EventInstance>()
  );
  const [isEventLoading, setIsEventLoading] = useState(true);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_UNTAPPD_API_BASE_URL;
    const user = process.env.REACT_APP_UNTAPPD_API_USER;
    const apikey = process.env.REACT_APP_UNTAPPD_API_KEY;
    const locationId = process.env.REACT_APP_UNTAPPD_LOCATION_ID;

    const fetchData = async () => {
      await axios
        .get(`${baseUrl}locations/${locationId}/events`, {
          auth: {
            username: user!,
            password: apikey!,
          },
        })
        .then((response) => {
          const eventData = response.data as UntappdEventResponse;

          let result = new Array<EventInstance>();

          var now = dayjs();
          var eventMaxDate = dayjs().add(maxEventDuration, "day");

          if (eventData !== null && eventData.events?.length > 0) {
            var currentEvents = eventData.events.filter(
              (sd) =>
                (dayjs(sd.start_time) > now || dayjs(sd.end_time) > now) &&
                dayjs(sd.start_time) < eventMaxDate
            );
            currentEvents.forEach((item, index, arr) => {
              const eventInstance = {
                id: arr[index].id,
                startDate: arr[index].start_time,
                endDate: arr[index].end_time,
                title: arr[index].name,
                description: arr[index].description,
              };
              result.push(eventInstance);
            });
          }

          setCurrentEvents(
            result.sort((a, b) => {
              var dateA = dayjs(a.startDate);
              var dateB = dayjs(b.startDate);
              if (dateA < dateB) return -1;
              if (dateA > dateB) return 1;
              return 0;
            })
          );
          setIsEventLoading(false);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };

    fetchData();
  }, []);

  return (
    <div className="m-0 p-0">
      <div className="w-full h-32 md:h-64 bg-fixed bg-cover bg-center flex justify-center items-center bg-store">
        <div className="p-2 w-full mx-2 md:p-5 md:w-3/4 bg-black/50">
          <p className="text-2xl md:text-6xl text-white font-roboto-slab">
            CRAFT BEER STORE
          </p>
          <p className="text-md md:text-2xl text-white font-roboto-slab">
            Craft Beer, Taphouse, and Bottleshop
          </p>
        </div>
      </div>
      <div className="pb-10 md:pb-20 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-10 md:gap-15 justify-center items-center bg-store">
        {/*         <HidableDiv
          className="w-full flex flex-col items-center content-center"
          dateToHide={new Date(2024, 3, 27, 23, 59, 59)}
        >
          <Card className="mx-5 md:w-1/2">
            <CardHeader shadow={false} floated={false} className="xs:h-12">
              <Typography variant="h5" color="black" className="text-wrap">
                April 27th, 2024 - John Pitone's Dance Party!
              </Typography>
            </CardHeader>
            <CardBody>
              <Typography variant="paragraph" component="div">
                Featuring a 2 hour open bar, delicious buffet, and dancing.
                Starting at 7 PM. See our Facebook post{" "}
                <Link
                  to="https://m.facebook.com/story.php?story_fbid=3583581331906053&id=1589797280&mibextid=SphRi8"
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  here.
                </Link>
              </Typography>
              <Typography variant="paragraph" component="div">
                <Link
                  to="https://www.eventbrite.com/e/ppe-presents-dj-john-pitones-dance-party-tickets-859473587637?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp"
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  $35.00 Buy Tickets
                </Link>
              </Typography>
            </CardBody>
          </Card>
        </HidableDiv>
 */}
        <Card className="mx-5 md:w-1/2">
          <CardHeader shadow={false} floated={false} className="h-15">
            <Typography
              variant="h4"
              style={{ color: "black" }}
              className="text-wrap"
            >
              News: Cascata to go menu now available for order!
            </Typography>
          </CardHeader>
          <CardBody>
            <Typography variant="paragraph">
              Unfortunately, our friends at Cascata Caffe are permanently
              closing their location at 801 W Sproul Rd, Springfield. As they
              actively pursue a new location, ready "To Go" meals will be
              available for purchase at the Craft Beer Store. Please see our
              Cascata Menu for pricing and details on our{" "}
              <Link
                to="https://order.toasttab.com/online/craft-beer-store-35-baltimore-pike"
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                target="_blank"
                rel="external noopener noreferrer"
              >
                Online Store
              </Link>
              .
            </Typography>
          </CardBody>
        </Card>

        <Card className="mx-5 md:w-1/2">
          <CardHeader
            shadow={false}
            floated={false}
            className="text-center h-8"
          >
            <Typography variant="h4" color="black">
              Weekly Events
            </Typography>
          </CardHeader>
          <CardBody className="text-center">
            {isEventLoading && (
              <div className="flex justify-center p-20">
                <CircularProgress />
              </div>
            )}
            {!isEventLoading &&
              currentEvents?.map((data, key) => {
                return (
                  <div key={data.id}>
                    <Typography variant="h5">
                      {dayjs(data.startDate).format("ddd MMM DD, YYYY")}
                    </Typography>
                    <Typography variant="paragraph">
                      {data.description}
                    </Typography>
                  </div>
                );
              })}
          </CardBody>
        </Card>
        <Card className="mx-5 md:w-1/2">
          <CardHeader shadow={false} floated={false} className="h-6">
            <Typography variant="h5" color="black">
              TouchTunes
            </Typography>
          </CardHeader>
          <CardBody>
            <Typography variant="paragraph">
              Touch Tunes is an interactive juke box that you control with an
              app on your phone. This juke box allows you to see what is playing
              as well as add songs to be played. For more information and to
              dowload the app visit the{" "}
              <Link
                to="https://www.touchtunes.com/"
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                target="_blank"
                rel="external noopener noreferrer"
              >
                TouchTunes
              </Link>{" "}
              website.
            </Typography>
          </CardBody>
        </Card>
        <Card className="mx-5 md:w-1/2">
          <CardHeader shadow={false} floated={false} className="h-6">
            <Typography variant="h5" color="black">
              Our Beers
            </Typography>
          </CardHeader>
          <CardBody>
            <Typography variant="paragraph">
              We have 30 rotating beers on draft and offer fresh crowler, and
              growler pours to go. See our tap list{" "}
              <Link
                to="/ontap"
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              >
                here
              </Link>
              . There are 18 fridges behind the main bar area filled with over
              750 beer singles to mix and match. We also have pre-assembled 4
              and 6 packs alphabetized by brewery. Grabbing a pint from the bar
              while shopping is not only allowed, but encouraged. You can also
              shop online{" "}
              <Link
                to="https://order.toasttab.com/online/craft-beer-store-35-baltimore-pike"
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                target="_blank"
                rel="external noopener noreferrer"
              >
                here
              </Link>
              .
            </Typography>
          </CardBody>
        </Card>
        <Card className="mx-5 md:w-1/2">
          <CardHeader shadow={false} floated={false} className="h-6">
            <Typography variant="h5" color="black">
              Our Mission
            </Typography>
          </CardHeader>
          <CardBody>
            <Typography variant="paragraph">
              To supply DELCO with the Best Craft Beer selection from around the
              United States, while supporting as many independent breweries as
              possible. From IPAs, Stouts, Sours, and more we want to educate
              and extend your beer drinking pallet, one beer at a time!
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
