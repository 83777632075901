import Typography from "@mui/material/Typography";

type Props = {
  abv: number;
  ibu: number;
  nitro: boolean;
};

export const BeerDetails = (props: Props) => {
  return (
    <>
      <Typography variant="subtitle1" component="div">
        {props.abv} ABV
      </Typography>
      {props.ibu > 0 && (
        <Typography variant="subtitle1" component="span">
          • {props.ibu} IBU
        </Typography>
      )}
      {props.nitro === true && (
        <Typography variant="subtitle1" component="span">
          • NITRO
        </Typography>
      )}
    </>
  );
};
