export const NotFound = () => {
  return (
    <div className="m-0 p-0">
      <div className="w-full h-32 md:h-64 bg-fixed bg-cover bg-center flex justify-center items-center bg-store">
        <div className="p-2 w-full mx-2 md:p-5 md:w-3/4 bg-black/50">
          <p className="text-2xl md:text-6xl text-white font-roboto-slab">
            404 Page Not Found
          </p>
          <p className="text-md md:text-2xl text-white font-roboto-slab">
            This is not a valid location. Please contact staff.
          </p>
        </div>
      </div>
    </div>
  );
};
