import { UntappdItem } from "../../models/UntappdItem";

class Sorting {
  sortTapsByTapNumber(a: UntappdItem, b: UntappdItem): number {
    const x = Number(a.tap_number);
    const y = Number(b.tap_number);
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  sortTapsByStyle(a: UntappdItem, b: UntappdItem): number {
    if ((a.short_style ?? a.style) < (b.short_style ?? b.style)) return -1;
    if ((a.short_style ?? a.style) > (b.short_style ?? b.style)) return 1;
    return 0;
  }

  sortTapsByName(a: UntappdItem, b: UntappdItem): number {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }

  sortTapsByBrewery(a: UntappdItem, b: UntappdItem): number {
    if (a.brewery < b.brewery) return -1;
    if (a.brewery > b.brewery) return 1;
    return 0;
  }
}

export default new Sorting();
