import React, { useState, useEffect } from "react";
import { PrintTapMenuConfig } from "../models/print-tap-menu-config";
import { UntappdItem } from "../models/UntappdItem";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { Typography } from "@material-tailwind/react";
import UntappdApi from "./api/Untappd-Api";

export const PrintTap = () => {
  const config: PrintTapMenuConfig = [
    {
      displayName: "SEASONAL",
      includes: ["pumpkin", "oktoberfest", "festbier", "märzen"],
      excludes: [],
    },
    {
      displayName: "AMERICAN PALE ALE",
      includes: ["pale ale"],
      excludes: [],
    },
    {
      displayName: "BELGIAN & STRONG ALE",
      includes: ["belgian", "strong ale"],
      excludes: [],
    },
    {
      displayName: "BROWN, AMBER, RED ALES",
      includes: ["brown", "amber", "red ale"],
      excludes: [],
    },
    {
      displayName: "CIDER, FRUIT, MALT",
      includes: ["cider", "fruit", "malt"],
      excludes: ["sour"],
    },
    {
      displayName: "HEFEWEIZEN",
      includes: ["hefeweizen"],
      excludes: [],
    },
    {
      displayName: "IPA",
      includes: ["ipa"],
      excludes: ["sour"],
    },
    {
      displayName: "KELLERBIER",
      includes: ["kellerbier"],
      excludes: [],
    },
    {
      displayName: "LAGER & PILSNER",
      includes: ["lager", "pilsner"],
      excludes: [],
    },
    {
      displayName: "PALE, BLONDE, GOLDEN ALES",
      includes: ["blonde"],
      excludes: [],
    },
    {
      displayName: "SOUR",
      includes: ["sour"],
      excludes: [],
    },
    {
      displayName: "STOUT & PORTER",
      includes: ["stout", "porter"],
      excludes: [],
    },
    {
      displayName: "WHEAT",
      includes: ["wheat"],
      excludes: ["hefeweizen"],
    },
    {
      displayName: "WILD ALE",
      includes: ["wild ale"],
      excludes: [],
    },
  ];
  const [isTapLoading, setIsTapLoading] = useState<boolean>(true);
  const [isOnDeckLoading, setIsOnDeckLoading] = useState<boolean>(true);
  const [page1Col1, setPage1Col1] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [page1Col2, setPage1Col2] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [page1Col3, setPage1Col3] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [page2Col1, setPage2Col1] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [page2Col2, setPage2Col2] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [page2Col3, setPage2Col3] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [onDeck, setOnDeck] = useState<UntappdItem[]>(new Array<UntappdItem>());

  useEffect(() => {
    const onTapSectionId = process.env.REACT_APP_UNTAPPD_BEER_SECTION_ID;
    const onDeckSectionId =
      process.env.REACT_APP_UNTAPPD_BEER_ON_DECK_SECTION_ID;
    const fetchData = async () => {
      let tapCount = 0;
      let temp_page2col3 = new Array<UntappdItem>();

      await UntappdApi.getTaps(onTapSectionId!).then(
        (response: UntappdItem[]) => {
          let itemMap = new Array<UntappdItem>();

          //console.log(JSON.stringify(response));

          for (let configItem of config) {
            //console.log(configItem.displayName);
            const beers = response.filter((beer) => {
              let include = false;
              for (let styleName of configItem.includes) {
                if (beer.short_style?.toLocaleLowerCase().includes(styleName))
                  include = true;
              }
              for (let styleName of configItem.excludes) {
                if (beer.short_style?.toLocaleLowerCase().includes(styleName))
                  include = false;
              }
              return include;
            });
            if (beers && beers.length > 0) {
              beers[0].menu_title = configItem.displayName;
              itemMap = itemMap.concat(beers);
            }
          }
          tapCount = itemMap.length;

          for (let i = 0; i < response.length; i++) {
            let found = itemMap.find(
              ({ tap_number }) => tap_number === response[i].tap_number
            );
            if (found === undefined) {
              console.log(response[i].tap_number, response[i].short_style);
            }
          }
          const numberOfRowsPerPage = 5;
          //Break up the menu into rows and columns
          let remaining = itemMap.length;
          if (remaining >= numberOfRowsPerPage) {
            setPage1Col1(itemMap.splice(0, numberOfRowsPerPage));
            remaining -= numberOfRowsPerPage;
          } else if (remaining > 0) {
            setPage1Col1(itemMap.splice(0, remaining));
            remaining = 0;
          }
          if (remaining >= numberOfRowsPerPage) {
            setPage1Col2(itemMap.splice(0, numberOfRowsPerPage));
            remaining -= numberOfRowsPerPage;
          } else if (remaining > 0) {
            setPage1Col2(itemMap.splice(0, remaining));
            remaining = 0;
          }
          if (remaining >= numberOfRowsPerPage) {
            setPage1Col3(itemMap.splice(0, numberOfRowsPerPage));
            remaining -= numberOfRowsPerPage;
          } else if (remaining > 0) {
            setPage1Col3(itemMap.splice(0, remaining));
            remaining = 0;
          }
          if (remaining >= numberOfRowsPerPage) {
            setPage2Col1(itemMap.splice(0, numberOfRowsPerPage));
            remaining -= numberOfRowsPerPage;
          } else if (remaining > 0) {
            setPage2Col1(itemMap.splice(0, remaining));
            remaining = 0;
          }
          if (remaining >= numberOfRowsPerPage) {
            setPage2Col2(itemMap.splice(0, numberOfRowsPerPage));
            remaining -= numberOfRowsPerPage;
          } else if (remaining > 0) {
            setPage2Col2(itemMap.splice(0, remaining));
            remaining = 0;
          }
          if (remaining >= numberOfRowsPerPage) {
            temp_page2col3 = itemMap.splice(0, numberOfRowsPerPage);
            setPage2Col3(temp_page2col3);
            remaining -= numberOfRowsPerPage;
          } else if (remaining > 0) {
            temp_page2col3 = itemMap.splice(0, remaining);
            setPage2Col3(temp_page2col3);
            remaining = 0;
          }

          setIsTapLoading(false);
        }
      );

      //Error too many taps
      if (tapCount > 35) {
        setIsTapLoading(false);
        setIsOnDeckLoading(false);
        console.log(`too many taps: ${tapCount}`);
        return;
      }

      await UntappdApi.getTaps(onDeckSectionId!).then(
        (response: UntappdItem[]) => {
          if (response.length > 0) {
            response[0].menu_title = "ON DECK";
          }
          setOnDeck(response);
          setIsOnDeckLoading(false);
        }
      );
    };
    fetchData();
  }, []);

  function getMenuColumn(items: UntappdItem[], includeOnDeck: boolean = false) {
    return (
      <div className="flex flex-col gap-1 w-full">
        {items.map((data, key) => {
          return (
            <div key={key} className="w-full h-[50mm]">
              <div key={data.tap_number}>
                {data?.menu_title && data?.menu_title?.length > 0 && (
                  <div className="font-bold bg-gray-400 w-full text-center text-sm mb-1">
                    {data.menu_title}
                  </div>
                )}
                <div>
                  <div className="text-xs font-bold">
                    {data.tap_number} • {data.name}
                  </div>
                  <div className="text-[10px]">{data.style}</div>
                  <div className="text-[10px]">{data.brewery}</div>
                  <div className="flex text-[10px]">
                    <span>{data.abv} ABV</span>
                    {data.ibu > 0 && <span>• {data.ibu} IBU</span>}
                    {data.nitro === true && <span>• NITRO</span>}
                  </div>
                  <div className="text-wrap flex flex-row flex-wrap">
                    {data.containers.length > 0 &&
                      data.containers.map((cont, ckey) => {
                        if (cont.id > 0) {
                          return (
                            <div
                              key={cont.id}
                              className="text-[10px] mr-2 basis-1/3"
                            >
                              <span>
                                {cont.currency_symbol}
                                {cont.price}
                              </span>
                              <span>
                                (
                                {
                                  cont.container_size.ounces
                                    .toLocaleString()
                                    .split(".")[0]
                                }
                                oz)
                              </span>
                            </div>
                          );
                        }
                      })}
                  </div>
                  <div className="text-[8px] mb-2 text-clip max-h-[15mm] overflow-hidden">
                    {data.description}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {includeOnDeck && getOnDeckBlock(onDeck)}
      </div>
    );
  }

  function getOnDeckBlock(items: UntappdItem[]) {
    return (
      <div className="flex flex-col gap-1 w-full">
        <div className="w-full h-[42mm]">
          <div>
            <div className="font-bold bg-gray-400 w-full text-center text-sm">
              ON DECK
            </div>
          </div>
          {items.map((data, key) => {
            return (
              <div key={key}>
                <div className="text-xs font-bold">{data.name}</div>
                <div className="text-xs">
                  <span className="italic">• {data.style}</span>
                  <span> - {data.abv} ABV</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function getMenu() {
    if (isTapLoading === true || isOnDeckLoading === true) {
      return (
        <div className="flex justify-center p-20">
          <Typography>Loading data from Untappd...</Typography>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div>
          <div className="grid grid-cols-3 gap-1 tap-menu-page1">
            <div className="col-span-3 h-auto">
              <h2 className="text-center">Craft Beer Store Tap List</h2>
            </div>
            <div>{getMenuColumn(page1Col1)}</div>
            <div>{getMenuColumn(page1Col2)}</div>
            <div>{getMenuColumn(page1Col3)}</div>
          </div>
          <div className="grid grid-cols-3 gap-1 tap-menu-page2">
            <div className="col-span-3 h-auto">
              <h1 className="text-center">Craft Beer Store Tap List</h1>
            </div>
            <div>{getMenuColumn(page2Col1)}</div>
            <div>{getMenuColumn(page2Col2)}</div>
            <div>{getMenuColumn(page2Col3, true)}</div>
          </div>
        </div>
      );
    }
  }

  function onPrintDiv() {
    if (isTapLoading === true) {
      return (
        <div className="flex justify-center p-20">
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div>
          <div className="print:hidden col-span-3">
            <Button
              variant="contained"
              onClick={() => {
                window.print();
              }}
            >
              Print
            </Button>
          </div>
          {getMenu()}
        </div>
      );
    }
  }

  return <div className="w-full h-max font-sans">{onPrintDiv()}</div>;
};
