import dayjs from "dayjs";

type Props = {
  label_image_thumb: string;
  name: string;
  created_at: string;
};

export const BeerImage = (props: Props) => {
  const isNew = (d: string): boolean => {
    var now = dayjs();
    if (dayjs(d).add(7, "day") > now) {
      return true;
    }
    return false;
  };

  return (
    <>
      <img
        className="w-[50] sm:w-[100] place-self-center"
        src={props.label_image_thumb}
        alt={props.name}
      />
      {isNew(props.created_at) && (
        <div className="rounded-full text-sm bg-amber-500 text-center font-bold max-w-20 place-self-center mt-2 px-2">
          NEW
        </div>
      )}
    </>
  );
};
