import { useState, useEffect } from "react";
import { UntappdItem } from "../../models/UntappdItem";
import UntappdApi from "../api/Untappd-Api";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Sorting from "../helpers/sorting";
import { BeerTapCard } from "./BeerTapCard";

export const OnTap = () => {
  const [sortBy, setSortBy] = useState<string>("1");
  const [isTapLoading, setIsTapLoading] = useState(true);
  const [isOnDeckLoading, setIsOnDeckLoading] = useState(true);
  const [tapData, setTapData] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [sortedTapData, setSortedTapData] = useState<UntappdItem[]>(
    new Array<UntappdItem>()
  );
  const [onDeckData, setOnDeckData] = useState<UntappdItem[] | null>(null);

  useEffect(() => {
    const onTapSectionId = process.env.REACT_APP_UNTAPPD_BEER_SECTION_ID;
    const onDeckSectionId =
      process.env.REACT_APP_UNTAPPD_BEER_ON_DECK_SECTION_ID;

    const fetchData = async () => {
      await UntappdApi.getTaps(onTapSectionId!).then(
        (response: UntappdItem[]) => {
          setTapData(response);
          setSortedTapData(response);
          setIsTapLoading(false);
        }
      );
      await UntappdApi.getTaps(onDeckSectionId!).then(
        (response: UntappdItem[]) => {
          setOnDeckData(response);
          setIsOnDeckLoading(false);
        }
      );
    };
    fetchData();
  }, []);

  function sortTapData(event: SelectChangeEvent) {
    setSortBy(event.target.value);
    let sortedData: UntappdItem[] = [];

    if (tapData === null) return;

    switch (event.target.value) {
      case "1":
        sortedData = tapData.sort((a, b) => Sorting.sortTapsByTapNumber(a, b));
        break;
      case "2":
        sortedData = tapData.sort((a, b) => Sorting.sortTapsByStyle(a, b));
        break;
      case "3":
        sortedData = tapData.sort((a, b) => Sorting.sortTapsByName(a, b));
        break;
      case "4":
        sortedData = tapData.sort((a, b) => Sorting.sortTapsByBrewery(a, b));
        break;
      case "5":
        sortedData = tapData.filter((t) => isNew(t.created_at));
    }
    setSortedTapData(sortedData);
  }

  function onTapDiv() {
    if (isTapLoading === true) {
      return (
        <div className="flex justify-center p-20">
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          {sortedTapData?.map((data, key) => {
            if (data.tap_number && data.tap_number !== "") {
              return (
                <>
                  <BeerTapCard tap_data={data} />
                </>
              );
            }
          })}
        </>
      );
    }
  }

  function onDeckDiv() {
    if (isOnDeckLoading === true) {
      return (
        <div className="flex justify-center p-20">
          <CircularProgress />
        </div>
      );
    } else if (onDeckData?.length ?? 0 > 0) {
      return (
        <>
          <div className="md:col-span-2 w-full h-8 bg-fixed bg-cover bg-center flex justify-center items-center">
            <div className="w-full p-0 m-0">
              <div className="bg-black/80 py-2 mb-2">
                <h1 className="text-2xl font-roboto-slab text-center text-white">
                  On Deck
                </h1>
              </div>
            </div>
          </div>
          {onDeckData?.map((data, key) => {
            if (data.name && data.name.length > 0) {
              return (
                <>
                  <BeerTapCard tap_data={data} />
                </>
              );
            }
          })}
        </>
      );
    } else {
      return <div></div>;
    }
  }

  const isNew = (d: string): boolean => {
    var now = dayjs();
    if (dayjs(d).add(7, "day") > now) {
      return true;
    }
    return false;
  };

  return (
    <div className="py-10 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-10 justify-center items-center bg-store">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 sm:mx-5 mx-3">
        <div className="md:col-span-2 w-full h-8 bg-fixed bg-cover bg-center flex justify-center items-center">
          <div className="w-full p-0 m-0">
            <div className="bg-black/80 py-2 mb-2">
              <h1 className="text-2xl font-roboto-slab text-center text-white">
                On Tap
              </h1>
            </div>
          </div>
        </div>

        <div className="md:col-span-2 w-full h-16 bg-fixed bg-cover bg-center flex justify-center items-center mt-5 mb-5">
          <Card raised={true} className="w-full md:w-[300]">
            <CardContent className="">
              <FormControl className="w-full">
                <InputLabel id="tap-label">Sort By</InputLabel>
                <Select
                  className="w-full"
                  id="tap-search"
                  labelId="tap-label"
                  label="Sort By"
                  value={sortBy}
                  onChange={sortTapData}
                >
                  <MenuItem value={"1"}>Tap Number</MenuItem>
                  <MenuItem value={"2"}>Style</MenuItem>
                  <MenuItem value={"3"}>Name</MenuItem>
                  <MenuItem value={"4"}>Brewery</MenuItem>
                  <MenuItem value={"5"}>New Only</MenuItem>
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </div>

        {onTapDiv()}
        {onDeckDiv()}
      </div>
    </div>
  );
};
