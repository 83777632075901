import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";

type Props = {
  rating: number;
};
export const BeerRating = (props: Props) => {
  return (
    <>
      <Rating
        precision={0.1}
        value={Number(props.rating)}
        max={5}
        size="medium"
        readOnly
      />
      <Typography className="place-self-start" component="span">
        {Number(props.rating).toFixed(2)}
      </Typography>
    </>
  );
};
