import { UntappdItemResponse } from "../../models/UntappdItemResponse";
import { UntappdItem } from "../../models/UntappdItem";
import Sorting from "../helpers/sorting";
import axios from "axios";

class UntappdApi {
  baseUrl = process.env.REACT_APP_UNTAPPD_API_BASE_URL;
  user = process.env.REACT_APP_UNTAPPD_API_USER;
  apikey = process.env.REACT_APP_UNTAPPD_API_KEY;
  onDeckSectionId = process.env.REACT_APP_UNTAPPD_BEER_ON_DECK_SECTION_ID;

  async getTaps(sectionId: string): Promise<UntappdItem[]> {
    let results: UntappdItem[] = [];

    await axios
      .get<UntappdItemResponse>(
        `${this.baseUrl}sections/${sectionId}/items?full=true`,
        {
          auth: {
            username: this.user!,
            password: this.apikey!,
          },
        }
      )
      .then((response) => {
        response.data.items.sort((a, b) => Sorting.sortTapsByTapNumber(a, b));
        results = response.data.items;
      })
      .catch((error) => {
        console.log(error);
      });

    return results;
  }
}

export default new UntappdApi();
