import { useState, ChangeEvent, FormEvent } from "react";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Typography,
} from "@material-tailwind/react";

export const Subscribe = () => {
  const [subscribeCommand, setSubscribeCommand] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hasError, setHasError] = useState(false);

  const mailchimp = require("@mailchimp/mailchimp_marketing");
  mailchimp.setConfig({
    apiKey: process.env.REACT_APP_MAILCHIMP_API_KEY,
    server: process.env.REACT_APP_MAILCHIMP_SERVER_PREFIX,
  });

  async function subscribe() {
    await mailchimp.lists
      .addListMember(process.env.REACT_APP_MAILCHIMP_AUDIENCE_ID, {
        email_address: subscribeCommand.email,
        status: "pending",
        merge_fields: {
          FNAME: subscribeCommand.firstName,
          LNAME: subscribeCommand.lastName,
        },
      })
      .then((response: any) => {
        console.log(response);
        setIsSubscribed(true);
      })
      .catch((error: any) => {
        console.log("Error", error);
        setHasError(true);
      });
  }

  const handleFirstNameChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSubscribeCommand({ ...subscribeCommand, firstName: event.target.value });
  };

  const handleLastNameChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setSubscribeCommand({ ...subscribeCommand, lastName: event.target.value });
  };

  const handleEmailChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setSubscribeCommand({ ...subscribeCommand, email: event.target.value });
  };

  const successAlert = () => {
    return (
      <div>
        <Alert
          variant="ghost"
          color="green"
          open={isSubscribed}
          onClose={() => setIsSubscribed(false)}
        >
          You have been successfully subscribed. Check your email to verify your
          subscription.
        </Alert>
      </div>
    );
  };

  const errorAlert = () => {
    return (
      <div>
        <Alert
          variant="ghost"
          color="red"
          open={hasError}
          onClose={() => setHasError(false)}
        >
          An error occurred. Did you enter all data and a valid email address?
        </Alert>
      </div>
    );
  };

  return (
    <div className="py-10 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-10 md:gap-20 justify-center items-center bg-store">
      <div className="w-full h-4 md:h-8 bg-fixed bg-cover bg-center flex justify-center items-center bg-store">
        <div className="w-full p-0 m-0">
          <div className="bg-black/80 sm:mx-10 sm:mt-5 py-2 mb-2">
            <h1 className="text-2xl font-roboto-slab text-center text-white">
              Subscribe to Newsletter
            </h1>
          </div>
        </div>
      </div>
      <div>
        <Card>
          <CardHeader
            shadow={false}
            floated={false}
            className="text-center h-6"
          >
            <Typography variant="h5" color="black">
              Subscribe to Newsletter
            </Typography>
          </CardHeader>
          <CardBody>
            {hasError && errorAlert()}
            {isSubscribed && successAlert()}
            <Typography variant="paragraph">
              Enter the information below to subscribe to our newsletter:
            </Typography>
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
              <div className="mb-4 flex flex-col gap-6">
                <Input
                  size="lg"
                  label="First Name"
                  value={subscribeCommand.firstName}
                  onChange={handleFirstNameChange}
                />
                <Input
                  size="lg"
                  label="Last Name"
                  value={subscribeCommand.lastName}
                  onChange={handleLastNameChange}
                />
                <Input
                  size="lg"
                  label="Email"
                  value={subscribeCommand.email}
                  onChange={handleEmailChange}
                />
              </div>
              <Button
                type="button"
                className="mt-6"
                fullWidth
                onClick={subscribe}
              >
                Subscribe
              </Button>
            </form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
