import dayjs from "dayjs";
import { Card, CardHeader, CardBody, Chip } from "@material-tailwind/react";
import { FunctionComponent } from "react";
import Typography from "@mui/material/Typography";
export type CalendarDayProps = {
  key: number;
  day: number;
  title: string;
  startTime?: string;
  endTime?: string;
};

export const CalendarDay: FunctionComponent<CalendarDayProps> = ({
  key,
  day,
  title,
  startTime,
  endTime,
}) => {
  let style = "p-2 ";
  if (day > 0) {
    style += "border border-solid border-black ml-[-1] mt-[-1]";
  }
  return (
    <div className={style} key={key}>
      <div className="grid grid-cols-[45px_auto]">
        {day > 0 && (
          <div className="w-full">
            <Chip
              className="rounded-full mr-2"
              size="md"
              variant="ghost"
              value={day}
            />
          </div>
        )}
        {day <= 0 && <div></div>}
        <div>
          <Typography variant="subtitle2">{title}</Typography>
          {startTime && startTime.length > 0 && (
            <Typography variant="subtitle2" component="span">
              {dayjs(startTime).format("h:mm A")}
            </Typography>
          )}
          {endTime && endTime.length > 0 && (
            <Typography variant="subtitle2" component="span">
              -{dayjs(endTime).format("h:mm A")}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
