import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./Home";
//import { Layout } from './Layout';
import { OnTap } from "./OnTap/OnTap";
import { FoodMenu } from "./FoodMenu";
import { NavFloating } from "./NavFloating";
//import { Events } from "./Events";
// import { EventManagement } from "./EventManagement";
import { Calendar } from "./Calendar";
import { EventsNew } from "./EventsNew";
import { Merchandise } from "./Merchandise";
import { Subscribe } from "./Subscribe";
import { NotFound } from "./NotFound";
import { PrintTap } from "./PrintTap";

const router = createBrowserRouter([
  {
    path: "/",
    element: <NavFloating />,
    errorElement: <NotFound />,
    children: [
      { path: "*", element: <NotFound /> },
      { index: true, element: <Home /> },
      { path: "ontap", element: <OnTap /> },
      { path: "foodmenu", element: <FoodMenu /> },
      //   { path: "events", element: <Events /> },
      { path: "calendar", element: <Calendar /> },
      { path: "events", element: <EventsNew /> },
      { path: "merchandise", element: <Merchandise /> },
      { path: "subscribe", element: <Subscribe /> },
      { path: "print", element: <PrintTap /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
