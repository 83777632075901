import { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import { UntappdEventResponse } from "../models/UntappdEventResponse";
import { EventInstance } from "../models/EventInstance";
//import untappdEventStore from "./stores/UntappdEventStore";
//import { observer } from "mobx-react";

export const EventsNew = () => {
  const maxEventDuration = 60; //days
  const initialEventsValue = {
    events: [
      {
        city: "",
        country: "",
        created_at: "",
        description: "",
        end_time: "",
        end_time_in_zone: "",
        from_facebook: false,
        id: 0,
        latitude: 0,
        link: "",
        location_id: 0,
        location_name: "",
        longitude: "",
        name: "",
        place_json: {
          id: "",
          url: "",
          icon: "",
          name: "",
          scope: "",
          types: [],
          geometry: {
            location: {
              lat: 0,
              lng: 0,
            },
          },
        },
        start_time: "",
        start_time_in_zone: "",
        state: "",
        street: "",
        updated_at: "",
        zip: "",
      },
    ],
  };

  const [eventData, setEventData] =
    useState<UntappdEventResponse>(initialEventsValue);
  const [isEventLoading, setIsEventLoading] = useState(true);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_UNTAPPD_API_BASE_URL;
    const user = process.env.REACT_APP_UNTAPPD_API_USER;
    const apikey = process.env.REACT_APP_UNTAPPD_API_KEY;
    const locationId = process.env.REACT_APP_UNTAPPD_LOCATION_ID;

    const fetchData = async () => {
      //untappdEventStore.getEvents();
      await axios
        .get(`${baseUrl}locations/${locationId}/events`, {
          auth: {
            username: user!,
            password: apikey!,
          },
        })
        .then((response) => {
          setEventData(response.data);
          setIsEventLoading(false);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };

    fetchData();
  }, []);

  const currentEvents = () => {
    let result = new Array<EventInstance>();

    var now = dayjs().subtract(1, "day");
    var eventMaxDate = dayjs().add(maxEventDuration, "day");

    if (eventData.events !== null && eventData.events?.length > 0) {
      var currentEvents = eventData.events.filter(
        (sd) =>
          dayjs(sd.start_time) > now && dayjs(sd.start_time) < eventMaxDate
      );
      currentEvents.forEach((item, index, arr) => {
        const eventInstance = {
          id: arr[index].id,
          startDate: arr[index].start_time,
          endDate: arr[index].end_time,
          title: arr[index].name,
          description: arr[index].description,
          link: arr[index].link,
        };
        result.push(eventInstance);
      });
    }
    return result.sort((a, b) => {
      var dateA = dayjs(a.startDate);
      var dateB = dayjs(b.startDate);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
  };

  if (isEventLoading === true) {
    return (
      <div className="flex justify-center p-20">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="py-10 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-10 md:gap-20 justify-center items-center bg-store">
      <div className="w-full h-8 md:h-16 bg-fixed bg-cover bg-center flex justify-center items-center bg-store">
        <div className="w-full p-0 m-0">
          <div className="bg-black/80 sm:mx-10 sm:mt-5 py-2 mb-2">
            <h1 className="text-2xl font-roboto-slab text-center text-white">
              Events
            </h1>
          </div>
        </div>
      </div>
      <div className="pb-10 md:pb-20 w-full h-max bg-fixed bg-cover bg-center flex flex-col gap-10 md:gap-15 justify-center items-center bg-store">
        {currentEvents()?.map((data, key) => {
          return (
            <Card className="mx-5 md:w-1/2" key={key}>
              <CardHeader
                shadow={false}
                floated={false}
                className="h-6 text-center"
              >
                <Typography variant="h5" color="black">
                  {data.title}
                </Typography>
              </CardHeader>
              <CardBody>
                <Typography
                  variant="paragraph"
                  color="black"
                  className="text-center"
                >
                  {dayjs(data.startDate).format("ddd MMM DD, YYYY")}
                </Typography>
                <Typography
                  variant="paragraph"
                  color="black"
                  className="text-center"
                >
                  {dayjs(data.startDate).format("h:mm A")}
                  {data.endDate && (
                    <> - {dayjs(data.endDate).format("h:mm A")}</>
                  )}{" "}
                </Typography>
                <Typography variant="paragraph">{data.description}</Typography>
              </CardBody>
            </Card>
          );
        })}
      </div>
    </div>
  );
};
