import Typography from "@mui/material/Typography";

type Props = {
  untappd_beer_slug: string;
  untappd_id: number;
  tap_number: string;
  tap_name: string;
};

export const BeerTapName = (props: Props) => {
  return (
    <>
      <a
        href={`https://untappd.com/b/${props.untappd_beer_slug}/${props.untappd_id}`}
        target="_blank"
        rel="noreferrer"
      >
        <Typography
          sx={{ textDecoration: "underline" }}
          className="hover:bg-gray-200"
          variant="h6"
          component="div"
        >
          {props.tap_number} • {props.tap_name}
        </Typography>
      </a>
    </>
  );
};
