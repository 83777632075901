import React, { FunctionComponent, MouseEventHandler } from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export const NavFloatingLink: FunctionComponent<{
  text: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  link?: string;
  href?: string;
  target?: string;
  rel?: string;
}> = (props) => {
  return (
    <Typography
      as="li"
      variant="small"
      color="blue-gray"
      className="p-1 font-normal bg-white hover:text-cbsRed-dark hover:bg-color-red hover:bg-red-50 rounded-lg"
    >
      {props.link && (
        <Link
          to={props.link}
          className="flex items-center"
          onClick={props.onClick}
          target={props.target ?? "_self"}
          rel={props.rel ?? ""}
        >
          {props.text}
        </Link>
      )}
      {props.href && (
        <a href={props.href} className="flex items-center">
          {props.text}
        </a>
      )}
    </Typography>
  );
};
