import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axios from "axios";
import { UntappdHoursResponse } from "../models/UntappdHoursResponse";

export const BusinessHours = () => {
  const initialHours = {
    hours: [
      {
        always_open: false,
        close_at: "",
        closed: false,
        created_at: "",
        day: "",
        id: 0,
        location_id: 0,
        open_at: "",
        updated_at: "",
      },
    ],
  };

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/New_York");

  const [isLoading, setIsLoading] = useState(true);
  const [hoursData, setHoursData] =
    useState<UntappdHoursResponse>(initialHours);

  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = process.env.REACT_APP_UNTAPPD_API_BASE_URL;
      const user = process.env.REACT_APP_UNTAPPD_API_USER;
      const apikey = process.env.REACT_APP_UNTAPPD_API_KEY;
      const locationId = process.env.REACT_APP_UNTAPPD_LOCATION_ID;

      await axios
        .get(`${baseUrl}locations/${locationId}/hours`, {
          auth: {
            username: user!,
            password: apikey!,
          },
        })
        .then((response) => {
          setHoursData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };

    fetchData();
  }, []);

  if (isLoading === true) {
    return <div></div>;
  }

  return (
    <div className="table text-md text-white table-auto border-seperate border-spacing-x-3">
      <div className="table-header-group">
        <div className="table-row">
          <div className="table-cell col-span-2 text-center text-xl bold border-separate pb-2">
            Business Hours
          </div>
        </div>
      </div>
      <div className="table-row w-full">
        {hoursData.hours?.map((data, key) => {
          return (
            <div className="table-row w-full" key={key}>
              <div className="table-cell">{data.day.toUpperCase()}</div>
              {(data.closed === false && (
                <div className="table-cell">
                  {dayjs.tz(data.open_at).format("hA")}-
                  {dayjs.tz(data.close_at).format("hA")}
                </div>
              )) ||
                (data.closed === true && (
                  <div className="table-cell">CLOSED</div>
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
