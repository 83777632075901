import { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

export const ShopIcon: FunctionComponent<{ className: string }> = ({
  className,
}) => {
  return (
    <a
      className={className}
      href="https://order.toasttab.com/online/craft-beer-store-35-baltimore-pike"
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon icon={faShoppingCart} />
      <span className="sr-only">Shop Online</span>
    </a>
  );
};
